import "./mod/avif-webp";
import "./mod/jquery.sticky-kit.min.js";
import firma from "console-signature";

(function ($) {
  ("use strict");
  const global = pct_globalVars;
  const $handler = $(".mobile-menu-handler");

  /*
  const Person = {
    name: "Oscar",
    children: [{ name: "Aisling", AnoNacimiento: 2003 }],
    anoNacimiento: 1969,
    favNumbers: [3, 11, 69, 31416],
  };

  const sumArray = ([numb1, numb2]) => numb1 + numb2;
  const getUserAge = ({ anoNacimiento }) =>
    new Date().getFullYear() - anoNacimiento;

  const getThirdElement = ([, , thirdNumber]) => thirdNumber;

  const isValidPerson = (person) =>
    person !== undefined && person.favNumbers !== undefined;

  console.log(`isValidPerson ? ===> ${isValidPerson()}`);
  console.log(`sumArray= ${sumArray([2, 3])}`);
  console.log(`Edad: ${getUserAge(Person)}`);
  console.log(`Cuarto Número favorito: ${getThirdElement(Person.favNumbers)}`);
*/
  /*------------------------------------------------------------------------------------------------------*\

							ONLOAD SECTION

	\*------------------------------------------------------------------------------------------------------*/

  $(window).on("load", function () {
    // 	WORDPRESS VIDEOPLAYER CUSTOMIZATION
    $(".mejs-overlay-loading").append(
      '<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>'
    );
    $(".mejs-overlay-loading span").remove();

    //sticky portfoliio description
    var $portDesc = $("body.single-portfolio_page .column2 .column_inner");
    $portDesc.stick_in_parent({ offset_top: 100 });

    // Change sidemenu icon on HEADER
    if(document.querySelector('.side_menu_button_link')){
      const el = document.querySelector('.side_menu_button_link')
      el.classList.add('pcticon-comments')
    }

  });

  firma();

  // HAMBURGUER MENU ICON ANIMATION ... USING SAME HANDLER AS BRIDGE THEME
  $(".mobile_menu_button > span").on("tap click", function (e) {
    e.preventDefault();
    $(".mobile_menu > ul").is(":visible")
      ? $handler.toggleClass("showing", false)
      : $handler.toggleClass("showing", true);
  });

  $(".mobile_menu > ul a").on("tap click", function (e) {
    /*e.preventDefault();*/
    console.log("LINK CLICADO");
    $handler.toggleClass("showing", false);
  });

  /**
   *  AVIF BACKGROUND IMAGES BY CLASS SELECTOR
   *  if not AVIF capabilities, then use a .jpg instead
   * AVIF capabilities checked by avif-webp.js, adding a webp/avif class to <html> tag.
   */

  [].slice
    .call(document.querySelectorAll('[class*="pct-avif-bg-"]'))
    .forEach(function (el) {
      var $el = $(el);
      var fileName = $el
        .attr("class")
        .match(/pct-avif-bg-(.*)+/)[0]
        .split(" ")[0]
        .split("pct-avif-bg-")[1];
      var filePath =
        global.mediaURL +
        "/" +
        fileName +
        "." +
        ($("html").hasClass("avif") ? "avif" : "jpg");

      $el.prepend('<div class="pct-bgimg"></div>');
      var $bg = $el.find(".pct-bgimg");
      $bg.css({
        "background-image": "url(" + filePath + ")",
        "background-size": "cover",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%",
      });
    });

  /*------------------------------------------------------------------------------------------------------*\

							ADD GUTENBRERG BLOCK CUSTOM HTML TAG ATTRIBUTE BY CLASSNAME ( data-attr-[attribute string] )

	\*------------------------------------------------------------------------------------------------------*/

  function addCustomAttrByClassname() {
    $('[class*="data-attr-"]').each(function (el) {
      const $el = $(this);

      const classesArr = $el.attr("class").split(" ");

      for (var i = 0; i < classesArr.length; i++) {
        if (classesArr[i].indexOf("data-attr-") > -1) {
          $el.attr({
            "data-q_id": "#" + classesArr[i].split("data-attr-")[1],
          });
        }
      }
    });
  }

  addCustomAttrByClassname();

  //qode_slider set fullHeight
  var pct_slider = $('[id*="-slider"]');
  var $windowWidth = $(window).width();

  if (pct_slider.length) {
    setSliderFullHeight(pct_slider);
    $(window).resize(function () {
      var gw_width = $(window).width();
      if ($windowWidth == gw_width) {
        return;
      } else {
        $windowWidth = gw_width;
      }
      clearTimeout(window.pct_sliderT);
      window.pct_sliderT = setTimeout(function () {
        setSliderFullHeight(pct_slider);
      }, 1000);
    });
  }

  function setSliderFullHeight(slider) {
    var windowH = $(window).height();
    var sliderX = pct_slider.offset().top;
    pct_slider.css({ "height": windowH - sliderX + "px" });
  }

  // ScrollTrigger.js launcher
  var triggerScope = {};

  var trigger = new ScrollTrigger({
    addHeight: true,
  });
  trigger.callScope = triggerScope;

  triggerScope.pct_animHided = function (value) {
    $(this).removeClass(value + "-hoverOut");
  };

  // Enhancing closing sidemenu on click outside
  $("section.side_menu + .wrapper").on("tap click", function (e) {
    window.closeSideMenu();
  });

  window.closeSideMenu = function (e) {
    if (
      $("body").hasClass("right_side_menu_opened") &&
      $("section.side_menu + .wrapper").position().left < 0
    ) {
      $("body").removeClass("right_side_menu_opened");
      $("a.side_menu_button_link").removeClass("opened");
      setTimeout(function () {
        $("section.side_menu").scrollTop(0);
      }, 1000);
    }
  };

  // shadow for circular mobile logotipe
  $(".header_top_bottom_holder").prepend(
    '<div class="circ_logo_shadow"></div>'
  );

  //FIXINGS FOR IE<12 for logo svg
  if (IEversion && IEversion < 12) {
    $(".logo_wrapper").css({
      "width": "132px",
    });
  }

  /*------------------------------------------------------------------------------------------------------*\

							CONTACT FORM 7 EVENTS

	\*------------------------------------------------------------------------------------------------------*/

  window.wpcf7.cached = 0;

  // trim polyfill : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
  if (!String.prototype.trim) {
    (function () {
      // Make sure we trim BOM and NBSP
      var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
      String.prototype.trim = function () {
        return this.replace(rtrim, "");
      };
    })();
  }

  [].slice
    .call(document.querySelectorAll(".input__field"))
    .forEach(function (inputEl) {
      // in case the input is already filled..
      if (inputEl.value.trim() !== "") {
        classie.add(inputEl.parentNode.parentNode, "input--filled");
      }

      // events:
      inputEl.addEventListener("focus", onInputFocus);
      inputEl.addEventListener("blur", onInputBlur);
    });

  function onInputFocus(ev) {
    classie.add(ev.target.parentNode.parentNode, "input--filled");
  }

  function onInputBlur(ev) {
    if (ev.target.value.trim() === "") {
      classie.remove(ev.target.parentNode.parentNode, "input--filled");
    }
  }

  //MENSAJE ENVIADO CORRECTAMENTE
  $('[role="form"].wpcf7').each(function (el) {
    var $form = $(this);
    var btsubmit = $('.wpcf7 button[id="submit"]').length
      ? $('.wpcf7 button[id="submit"]')
      : $('.wpcf7 input[type="submit"]');
    var btsubmit_txt = btsubmit.text();

    $form.prepend($form.find(".success-msg"));

    $("button#re-send").on("tap click submit", function (e) {
      e.preventDefault();
      $('[role="form"].wpcf7 .success-msg').toggleClass("msg-hidden");
      $('[role="form"].wpcf7 form').toggleClass("form-hidden");

      $('[role="form"].wpcf7 .wpcf7-submit').removeClass(
        "success animated pulse"
      );
      btsubmit.text(btsubmit_txt);

      $form.find(".wpcf7-response-output").text("");
      $form.find(".wpcf7-response-output").removeClass("wpcf7-mail-sent-ok");
      $form.find(".input--filled").removeClass("input--filled");
    });
  });

  //BOTÓN SUBMIT CLICADO (jQuery)
  var btsubmit = $('.wpcf7 button[id="submit"]').length
    ? $('.wpcf7 button[id="submit"]')
    : $('.wpcf7 input[type="submit"]');
  var btLoaderAsync;

  $(btsubmit).on("tap click submit", function (e) {
    $(".ajax-loader").remove();
    var el = $(this);

    if (el.hasClass("active") || el.hasClass("success")) {
      return false;
    }

    el.addClass("active");
    el.addClass("loader");

    btLoaderAsync = setTimeout(function () {
      el.addClass("loader");
    }, 300);
  });

  $(btsubmit).on("mouseenter touchstart", function (e) {
    //console.log('SUBMIT ROLLED OVER');
  });

  $(btsubmit).on("mouseleave touchend touchcancel", function (e) {
    //console.log('SUBMIT ROLLED OUT');
  });

  // EMAIL ENVIADO CORRECTAMENTE, REDIRIGIMOS A PÁGINA DE GRACIAS PARA EL FORMULARIO CON ID 3535 (comentado).
  document.addEventListener(
    "wpcf7mailsent",
    function (event) {
      clearInterval(btLoaderAsync);
      btsubmit.removeClass("loader active");
      btsubmit.text("¡Gracias!");
      btsubmit.addClass("success animated pulse");

      $('[role="form"].wpcf7 .success-msg').toggleClass("msg-hidden");
      $('[role="form"].wpcf7 form').toggleClass("form-hidden");

      //if ( '3535' == event.detail.contactFormId ) {location = 'http://www.motorvillagemadrid.com/gracias';}
      //console.log('FORMULARIO ENVIADO: todo OK: From ID--> ' + event.detail.contactFormId);
    },
    false
  );

  // ERROR DE VALIDACIÓN, ALGÚN CAMPO NO ES CORRECTO O ES OBLIGATORIO Y ESTÁ VACÍO
  document.addEventListener(
    "wpcf7invalid",
    function (event) {
      clearInterval(btLoaderAsync);
      btsubmit.removeClass("loader active");
      btsubmit.text("Enviar de nuevo");
    },
    false
  );

  // OK, PERO EL SERVIDOR NO HA PODIDO ENVIAR EL MAIL. PROBLEMA EN EL SERVIDOR SMTP¿?
  document.addEventListener(
    "wpcf7mailfailed",
    function (event) {
      clearInterval(btLoaderAsync);
      btsubmit.removeClass("loader active");
      btsubmit.text("Volver a enviar");
      //console.log('NO SE HA PODIDO ENVIAR EL MAIL: ' + event.detail.contactFormLocale);
    },
    false
  );

  // OK, PERO EL SERVIDOR NO HA PODIDO ENVIAR EL MAIL. PROBLEMA EN EL SERVIDOR SMTP¿?
  document.addEventListener(
    "wpcf7spam",
    function (event) {
      clearInterval(btLoaderAsync);
      btsubmit.removeClass("loader active");
      btsubmit.text("Volver a enviar");
      //console.log('NO SE HA PODIDO ENVIAR EL MAIL: ' + event.detail.contactFormLocale);
      console.log("Se ha considerado SPAM");
    },
    false
  );

  /*------------------------------------------------------------------------------------------------------*\

							CONTACT FORM 7 LEGAL CHECKBOX STYLED

	\*------------------------------------------------------------------------------------------------------*/

  var cId = 0; // to set an unique id for each checkbox...

  [].slice
    .call(document.querySelectorAll(".pct-legal-acceptance"))
    .forEach(function (el) {
      var $legalEl = $(el);
      cId++;
      var $check = $legalEl.find('input[type="checkbox"]');
      var $label = $legalEl.parent().parent().find(".pct-label-for-legal");

      //ocultamos el checkbox y su wrapper, incluyendo su pseudo-label
      //check.parent().css({'display': 'none'});

      $check.attr({ "id": $label.attr("for") + cId });
      $check.after(
        '<label for="' +
          $label.attr("for") +
          cId +
          '">' +
          $label.html() +
          "</label>"
      );
      $check.css({ "display": "none" });

      //eliminamos los elementos innecesarios de CF7
      $label.remove();
      $legalEl.find($(".wpcf7-list-item-label")).remove();
    });

  /*------------------------------------------------------------------------------------------------------*\

							CONTACT FORM 7 ADD CLIENT ID from ANALYTICS COOKIE as input hidden for user

	\*------------------------------------------------------------------------------------------------------*/

  [].slice
    .call(document.querySelectorAll("form.wpcf7-form"))
    .forEach(function (el) {
      var $form = $(el);
      var gaClientID =
        document.cookie.indexOf("_ga=") > -1 ? get_GAclientID() : null;

      if (gaClientID) {
        $form.find("input.gacid").attr({
          "value": gaClientID,
        });
      }
      //console.log('clientID= ' + $form.find('input.gacid').attr('value'));
    });

  function get_GAclientID() {
    var gaCookie = document.cookie.split("_ga=")[1].split(";")[0].split(".");
    var clientID = gaCookie[2] + "." + gaCookie[3];
    //console.log(clientID);
    return clientID;
  }

  /*------------------------------------------------------------------------------------------------------*\

							BOTONES EVENTS

	\*------------------------------------------------------------------------------------------------------*/

  var btPct = $("a.boton");
  btPct.prepend('<span class="bt-inner-left"></span>');
  btPct.append(
    '<span class="bt-inner-right"></span><span class="bt-bg"></span>'
  );

  var btBg = $("a.boton .bt-bg"); //background for events
  btPct.addClass("inactive");

  var defWidth = btPct.outerWidth() + 2;
  btPct.css({ "width": defWidth });

  btBg.on("tap click submit", function (e) {
    var el = $(this).parent();
    if (el.hasClass("clicked") || el.hasClass("success")) {
      return false;
    }

    el.addClass("clicked");
  });

  btBg.on("mouseenter touchstart", function (e) {
    var el = $(this).parent();
    if (el.hasClass("hover")) {
      return false;
    }
    el.addClass("hover");
    el.removeClass("inactive");
    btPct.css({ "width": defWidth + 80 });
  });

  btBg.on("mouseleave touchend touchcancel", function (e) {
    var el = $(this).parent();
    el.removeClass("hover");
    el.removeClass("clicked");
    el.addClass("inactive");
    btPct.css({ "width": defWidth });
    //console.log('SUBMIT ROLLED OUT');
  });

  /*------------------------------------------------------------------------------------------------------*\

							BOTONES SVG

	\*------------------------------------------------------------------------------------------------------*/

  [].slice
    .call(document.querySelectorAll(".boton-svg"))
    .forEach(function (element) {
      var $el = $(element),
        $svg_def,
        $svg_over;
      var edgetxtFix = 0;
      var bt_stroke = "8px";
      var text = $el.text();
      var minWidth = 150;
      var dim = [
        $el.outerWidth() < minWidth ? minWidth : $el.outerWidth(),
        $el.outerHeight(),
      ];
      //styles
      var styles = {
        "font-family": '"Open Sans",Helvetica,Arial,sans-serif',
        "padding": { "v": 12, "h": 20 },
        "size": "2rem",
      };
      var animationType = $el.attr("data-animation");

      //console.log('Width: ' + $el.outerWidth() + ' outerHeight: '+ $el.outerHeight() +' text: '+ text + 'innerHTML: ' + element.innerHTML);

      addSvg($el, dim, text, styles);

      function addSvg(targetEl, dim, txt, styles) {
        var randID = "bt-" + Math.floor(Math.random() * 100 + 1);

        targetEl.empty();
        var outerDim = [
          dim[0] + styles.padding.h * 2,
          dim[1] + styles.padding.v * 2,
        ];
        var vCenter = outerDim[1] / 2;

        //IExplorer 11- --> fallback to simple animation
        if (IEversion && IEversion < 12) {
          animationType = "simple";
          edgetxtFix = 6;
          bt_stroke = "4px";
        } else if (IEversion && IEversion >= 12) {
          edgetxtFix = 6;
        }

        $el.append(
          '<div style="perspective:400px;display:inline-block;"><svg class="svg-default" id="' +
            randID +
            '_def" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg><svg class="svg-over" id="' +
            randID +
            '_over" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg></div>'
        );

        $svg_def = $("#" + randID + "_def");
        $svg_over = $("#" + randID + "_over");
        var s_def = Snap("#" + randID + "_def");
        var s_over = Snap("#" + randID + "_over");

        var btBg = s_def
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "class": "svg-bg" });
        var btTxt = s_def
          .text(outerDim[0] / 2, vCenter + edgetxtFix, txt)
          .attr({ "class": "svgText", "dominant-baseline": "central" });
        var btState1 = s_def.group(btBg, btTxt).attr({ "class": "btState1" });

        var masked = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "masked btSate2" });
        var btState2 = s_over.group(masked).attr({ "class": "btState2" });

        //MASK
        var maskBG = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "svg-bg inmask" });
        var maskTxt = s_over
          .text(outerDim[0] / 2, vCenter + edgetxtFix, txt)
          .attr({ "class": "svgText inmask", "dominant-baseline": "central" });

        var mask = s_over.group(maskBG, maskTxt).attr({ "class": "mask" });

        // SET CSS STYLES (...so it can be taken out to a .css file)
        var $bg = $svg_def.find(".svg-bg");
        var $txt = $svg_def.find(".svgText");
        var $masked = $svg_over.find(".masked");
        var $mask = $svg_over.find(".mask");
        var $maskBG = $svg_over.find(".svg-bg.inmask");
        var $maskTxt = $svg_over.find(".svgText.inmask");

        var bgCSS = {
          "fill": "rgba(255,255,255,0)",
          "stroke": "rgba(255,255,255,1)",
          "stroke-width": bt_stroke,
          "transition": "all .3s ease",
        };

        var txtCSS = {
          "fill": "#FFF",
          "font-size": "1rem",
          "font-family": "Lato,Helvetica,Arial,sans-serif",
          "letter-spacing": "2px",
          "font-weight": "900",
          "font-style": "italic",
          "line-height": "1rem",
          "text-anchor": "middle",
          "alignment-baseline": "central",
        };

        $bg.css(bgCSS);
        $txt.css(txtCSS);
        $maskTxt.css(txtCSS);
        $maskTxt.css({ "fill": "rgba(0,0,0,1)" });

        //transitions
        $svg_def.css({
          "transition": "all .3s ease",
          "backface-visibility": "hidden",
          "will-change": "transform",
          "transform": "translate3d(0px,0px," + vCenter + "px)",
        });
        $svg_over.css({
          "transition": "all .3s ease",
          "position": "absolute",
          "left": "0",
          "top": "0",
          "opacity": "0",
          "backface-visibility": "hidden",
          "will-change": "transform",
          "transform": "translate3d(0px,0px," + vCenter + "px)",
        });

        //aplicamos máscara
        masked.attr({ "mask": mask });

        // ESTILOS PARA BOTÓN CON FONDO BLANCO
        if ($el.hasClass("boton-bg-light")) {
          //console.log('BOTÓN EN FONDO LIGHT' + $('.boton-svg.boton-bg-light').text());
          $bg.css({
            "stroke": "rgba(0,0,0,1)",
          });
          $txt.css({
            "fill": "rgba(0,0,0,1)",
          });
          $masked.css({ "fill": "rgba(0,0,0,1)" });
        }

        // SETUP ANIMATION ON MOUSEOVER
        if (!animationType || animationType == "simple") {
          //events
          $el.on("mouseenter touchstart", function (e) {
            $svg_def.css({ "opacity": 0 });
            $svg_over.css({ "opacity": 1 });
          });

          $el.on("mouseleave touchend touchcancel", function (e) {
            $svg_def.css({ "opacity": 1 });
            $svg_over.css({ "opacity": 0 });
          });
        } else if (animationType == "3Dflip") {
          $svg_over.css({
            "transform":
              "rotate3d(1,0,0,-85deg) translate3d(0px,0px," + vCenter + "px)",
            "opacity": "1",
          });

          //events
          $el.on("mouseenter touchstart", function (e) {
            $svg_def.css({
              "transform":
                "rotate3d(1,0,0,90deg) translate3d(0px,0px," + vCenter + "px)",
            });
            $svg_over.css({
              "transform":
                "rotate3d(1,0,0,0deg) translate3d(0px,0px," + vCenter + "px)",
              "opacity": "1",
            });
          });

          $el.on("mouseleave touchend touchcancel", function (e) {
            $svg_def.css({
              "transform":
                "rotate3d(1,0,0,0deg) translate3d(0px,0px," + vCenter + "px)",
            });
            $svg_over.css({
              "transform":
                "rotate3d(1,0,0,-85deg) translate3d(0px,0px," + vCenter + "px)",
              "opacity": "1",
            });
          });
        }

        if ($el.attr("data-bt-click") !== "false") {
          $el.on("tap click", function (e) {
            window[$el.attr("data-bt-click")]();
          });
        }
      }
    });

  /*------------------------------------------------------------------------------------------------------*\

							BOTONES SHOWMORE SVG

	\*------------------------------------------------------------------------------------------------------*/

  [].slice
    .call(document.querySelectorAll(".showmore-svg"))
    .forEach(function (element) {
      var $el = $(element),
        $svg_def,
        $svg_over;
      var $target_element = $("#" + $el.attr("data-target"));
      $target_element.sm_opened = false;
      //					$target_element.defHeight = getSMtargetHeight($target_element);
      $target_element.defHeight = $target_element.outerHeight();
      var $windowWidth = $(window).width();

      var edgetxtFix = 0;
      var bt_stroke = 8;
      var bg_stroke = IEversion && IEversion < 12 ? 4 : 8; // for IE < 12
      var text = $el.text();
      var iconSize = 20;
      var padding = 10;
      //var dim = [($el.outerWidth()<minWidth ? minWidth : $el.outerWidth()),$el.outerHeight()];
      //styles
      var styles = {
        "font-family": "pictauicon",
        "padding": padding,
        "size": "2rem",
      };
      //var animationType = $el.attr('data-animation');

      //console.log('Width: ' + $el.outerWidth() + ' outerHeight: '+ $el.outerHeight() +' text: '+ text + 'innerHTML: ' + element.innerHTML);

      addSvg($el, text, iconSize, styles);

      function addSvg(targetEl, txt, iconSize, styles) {
        var randID = "btsm-" + Math.floor(Math.random() * 100 + 1);

        targetEl.empty();
        var outerDim = [
          iconSize + styles.padding * 2,
          iconSize + styles.padding * 2,
        ];

        $el.append(
          '<svg class="smsvg-default" id="' +
            randID +
            '_def" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg><svg class="smsvg-over" id="' +
            randID +
            '_over" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg>'
        );

        $svg_def = $("#" + randID + "_def");
        $svg_over = $("#" + randID + "_over");
        var s_def = Snap("#" + randID + "_def");
        var s_over = Snap("#" + randID + "_over");

        var btBg = s_def
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "class": "svg-bg" });

        var icoPlusH = s_def
          .line(
            styles.padding,
            outerDim[0] / 2,
            iconSize + styles.padding,
            outerDim[0] / 2
          )
          .attr({
            "stroke": "#000",
            "stroke-width": bt_stroke / 2 + "px",
            "class": "icon-sm",
          });
        var icoPlusV = s_def
          .line(
            outerDim[0] / 2,
            padding,
            outerDim[0] / 2,
            iconSize + styles.padding
          )
          .attr({
            "stroke": "#000",
            "stroke-width": bt_stroke / 2 + "px",
            "class": "icon-sm plus-vertical",
          });
        var icoPlus = s_def.group(icoPlusH, icoPlusV);

        var btState1 = s_def.group(btBg, icoPlus).attr({ "class": "btState1" });

        var masked = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "masked btSate2" });
        var btState2 = s_over.group(masked).attr({ "class": "btState2" });

        //MASK
        var maskBG = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "svg-bg inmask" });
        var maskicoPlusH = s_over
          .line(
            styles.padding,
            outerDim[0] / 2,
            iconSize + styles.padding,
            outerDim[0] / 2
          )
          .attr({
            "stroke": "#000",
            "stroke-width": bt_stroke / 2 + "px",
            "class": "icon-sm inmask",
          });
        var maskicoPlusV = s_over
          .line(
            outerDim[0] / 2,
            padding,
            outerDim[0] / 2,
            iconSize + styles.padding
          )
          .attr({
            "stroke": "#000",
            "stroke-width": bt_stroke / 2 + "px",
            "class": "icon-sm plus-vertical inmask",
          });
        var maskicoPlus = s_over.group(maskicoPlusH, maskicoPlusV);

        var mask = s_over.group(maskBG, maskicoPlus).attr({ "class": "mask" });

        // SET CSS STYLES (...so it can be taken out to a .css file)
        var $bg = $svg_def.find(".svg-bg");
        var $bg_over = $svg_over.find(".svg-bg");

        var $icon = $svg_def.find(".icon-sm");
        var $masked = $svg_over.find(".masked");
        var $mask = $svg_over.find(".mask");
        var $maskBG = $svg_over.find(".svg-bg.inmask");
        var $maskIcon = $svg_over.find(".icon-sm.inmask");

        $el.css({ "position": "relative" });

        var bgCSS = {
          "fill": "rgba(255,255,255,0)",
          "stroke": "rgba(255,255,255,1)",
          "stroke-width": bg_stroke + "px",
          "transition": "all .3s ease",
        };

        var iconCSS = {
          "stroke": "#ffffff",
        };

        $bg.css(bgCSS);
        $icon.css(iconCSS);
        $masked.css({ "stroke-width": bg_stroke + "px" }); //for IE < 12

        //transitions
        $svg_def.css({
          "transition": "all .3s ease",
          "will-change": "transform",
          "position": "relative",
        });
        $svg_over.css({
          "transition": "all .3s ease",
          "position": "absolute",
          "left": "0",
          "top": "0",
          "opacity": "0",
          "will-change": "transform",
        });

        //aplicamos máscara
        masked.attr({ "mask": mask });

        // ESTILOS PARA BOTÓN CON FONDO BLANCO
        if ($el.hasClass("showmore-bg-light")) {
          //console.log('BOTÓN EN FONDO LIGHT' + $('.boton-svg.boton-bg-light').text());
          $bg.css({
            "stroke": "rgba(0,0,0,1)",
          });
          $masked.css({ "fill": "rgba(0,0,0,1)" });
          $icon.css({ "stroke": "#000" });
        }

        // SETUP TARGET ELEMENT
        $target_element.css({
          "height": "0",
          "overflow": "hidden",
          "transition": "all 1s ease",
        });

        // SETUP ANIMATION ON MOUSEOVER
        $svg_over.css({
          /*'transform' : 'rotate3d(1,0,0,-85deg) translate3d(0px,0px,'+ vCenter +'px)',*/
          "opacity": "0",
        });

        //events
        $el.on("mouseenter touchstart", function (e) {
          $svg_def.css({
            /*'transform' : 'rotate3d(1,0,0,90deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "0",
          });
          $svg_over.css({
            /*'transform' : 'rotate3d(1,0,0,0deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "1",
          });
        });

        $el.on("mouseleave touchend touchcancel", function (e) {
          $svg_def.css({
            /*'transform' : 'rotate3d(1,0,0,0deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "1",
          });
          $svg_over.css({
            /*'transform' : 'rotate3d(1,0,0,-85deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "0",
          });
        });

        $el.on("click tap", function (e) {
          event.preventDefault();
          openSMtarget($target_element, null, $(this));
        });
      }

      function openSMtarget(target, forceClose, el) {
        if (target.sm_opened || forceClose) {
          // hiding
          target.css({ "height": "0px" });
          target.sm_opened = false;
          el.find(".plus-vertical").css({ "display": "inherit" });
        } else {
          // shwoing
          target.css({ "height": target[0].scrollHeight + "px" });
          target.sm_opened = true;
          el.find(".plus-vertical").css({ "display": "none" });
        }
      }

      //get new target element height after window.resize
      $(window).resize(function () {
        var gw_width = $(window).width();
        if ($windowWidth == gw_width) {
          return;
        } else {
          $windowWidth = gw_width;
        }
        clearTimeout($target_element.ut);
        $target_element.ut = setTimeout(function () {
          if ($target_element.sm_opened) {
            openSMtarget($target_element, true, $el);
            console.log($target_element.sm_opened);
          }
        }, 1000);
      });
    });

  /*------------------------------------------------------------------------------------------------------*\

							BOTONES ICON SVG

	\*------------------------------------------------------------------------------------------------------*/

  [].slice
    .call(document.querySelectorAll(".boton-icon"))
    .forEach(function (element) {
      var $el = $(element),
        $svg_def,
        $svg_over;
      var edgetxtFix = 0;
      var bt_stroke = 8;
      var bg_stroke = IEversion && IEversion < 12 ? 4 : 8; // for IE < 12
      var text = $el.text();
      var iconSize = 20;
      var padding = 10;
      var def_unicodes = {
        "prev": "f053",
        "next": "f054",
        "home": "f009",
      };
      var unicode =
        "&#x" +
        ($el.attr("data-type")
          ? def_unicodes[$el.attr("data-type")]
          : $el.attr("data-bt-icon")
          ? $el.attr("data-bt-icon")
          : def_unicodes.home) +
        ";";

      //styles
      var styles = {
        "font-family": "pictauicon",
        "padding": padding,
        "size": "2rem",
      };

      //IExplorer 11- --> fallback to simple animation
      if (IEversion && IEversion < 12) {
        edgetxtFix = 6;
        bt_stroke = "4px";
      } else if (IEversion && IEversion >= 12) {
        edgetxtFix = 6;
      }

      addSvg($el, text, iconSize, styles);

      function addSvg(targetEl, txt, iconSize, styles) {
        var randID = "btico-" + Math.floor(Math.random() * 100 + 1);
        var outerDim = [
          iconSize + styles.padding * 2,
          iconSize + styles.padding * 2,
        ];
        var vCenter = outerDim[1] / 2;

        targetEl.empty();

        $el.append(
          '<svg class="btico-default" id="' +
            randID +
            '_def" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg><svg class="btico-over" id="' +
            randID +
            '_over" width="' +
            outerDim[0] +
            '" height="' +
            outerDim[1] +
            '"></svg>'
        );

        $svg_def = $("#" + randID + "_def");
        $svg_over = $("#" + randID + "_over");
        var s_def = Snap("#" + randID + "_def");
        var s_over = Snap("#" + randID + "_over");

        var btBg = s_def
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "class": "svg-bg" });
        var btTxt = s_def
          .text(
            outerDim[0] / 2,
            vCenter + edgetxtFix,
            $.parseHTML(unicode)[0].data
          )
          .attr({ "class": "svgText", "dominant-baseline": "central" });

        var btState1 = s_def.group(btBg, btTxt).attr({ "class": "btState1" });

        var masked = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "masked btSate2" });
        var btState2 = s_over.group(masked).attr({ "class": "btState2" });

        //MASK
        var maskBG = s_over
          .rect(0, 0, outerDim[0], outerDim[1])
          .attr({ "fill": "rgba(255,255,255,1)", "class": "svg-bg inmask" });
        var maskTxt = s_over
          .text(
            outerDim[0] / 2,
            vCenter + edgetxtFix,
            $.parseHTML(unicode)[0].data
          )
          .attr({ "class": "svgText inmask", "dominant-baseline": "central" });

        var mask = s_over.group(maskBG, maskTxt).attr({ "class": "mask" });

        // SET CSS STYLES (...so it can be taken out to a .css file)
        var $bg = $svg_def.find(".svg-bg");
        var $bg_over = $svg_over.find(".svg-bg");

        var $icon = $svg_def.find(".icon-sm");
        var $masked = $svg_over.find(".masked");
        var $mask = $svg_over.find(".mask");
        var $maskBG = $svg_over.find(".svg-bg.inmask");
        var $maskIcon = $svg_over.find(".icon-sm.inmask");
        var $txt = $el.find(".svgText");
        var $maskTxt = $el.find(".svgText.inmask");

        $el.css({ "position": "relative" });

        var bgCSS = {
          "fill": "rgba(255,255,255,0)",
          "stroke": "rgba(255,255,255,1)",
          "stroke-width": bg_stroke + "px",
          "transition": "all .3s ease",
        };

        var iconCSS = {
          "stroke": "#ffffff",
        };

        var txtCSS = {
          "fill": "#FFF",
          "font-size": "18px",
          "font-family": "pictauicon",
          /*'line-height' : '0px',*/
          "text-anchor": "middle",
          "alignment-baseline": "central",
          "transform": "translateY(1px)",
        };

        $txt.css(txtCSS);
        $maskTxt.css(txtCSS);
        $maskTxt.css({ "fill": "rgba(0,0,0,1)" });

        $bg.css(bgCSS);
        $icon.css(iconCSS);
        $masked.css({ "stroke-width": bg_stroke + "px" }); //for IE < 12

        //transitions
        $svg_def.css({
          "transition": "all .3s ease",
          "will-change": "transform",
          "position": "relative",
          "top": "0",
          "left": "0",
        });
        $svg_over.css({
          "transition": "all .3s ease",
          "position": "absolute",
          "left": "0",
          "opacity": "0",
          "will-change": "transform",
        });

        //aplicamos máscara
        masked.attr({ "mask": mask });

        // ESTILOS PARA BOTÓN CON FONDO BLANCO
        if ($el.hasClass("boton-icon-bg-light")) {
          //console.log('BOTÓN EN FONDO LIGHT' + $('.boton-svg.boton-bg-light').text());
          $bg.css({
            "stroke": "rgba(0,0,0,1)",
          });
          $masked.css({ "fill": "rgba(0,0,0,1)" });
          $txt.css({ "fill": "#000" });
        }

        // SETUP ANIMATION ON MOUSEOVER
        $svg_over.css({
          /*'transform' : 'rotate3d(1,0,0,-85deg) translate3d(0px,0px,'+ vCenter +'px)',*/
          "opacity": "0",
        });

        //events
        $el.on("mouseenter touchstart", function (e) {
          $svg_def.css({
            /*'transform' : 'rotate3d(1,0,0,90deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "0",
          });
          $svg_over.css({
            /*'transform' : 'rotate3d(1,0,0,0deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "1",
          });
        });

        $el.on("mouseleave touchend touchcancel", function (e) {
          $svg_def.css({
            /*'transform' : 'rotate3d(1,0,0,0deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "1",
          });
          $svg_over.css({
            /*'transform' : 'rotate3d(1,0,0,-85deg) translate3d(0px,0px,'+ vCenter +'px)',*/
            "opacity": "0",
          });
        });
      }
    });

  /*------------------------------------------------------------------------------------------------------*\

							custom mouseover on scrolltrigger animations homepage

	\*------------------------------------------------------------------------------------------------------*/
  $(".webAnim").on("mouseenter touchstart", function (e) {
    var el = $(this);
    el.addClass("webAnim-hoverIn");
    el.removeClass("webAnim-hoverOut");
  });

  $(".webAnim").on("mouseleave touchend touchcancel", function (e) {
    var el = $(this);
    el.removeClass("webAnim-hoverIn");
    el.addClass("webAnim-hoverOut");
  });

  $(".analiticAnim").on("mouseenter touchstart", function (e) {
    var el = $(this);
    el.addClass("analiticAnim-hoverIn");
    el.removeClass("analiticAnim-hoverOut");
  });

  $(".analiticAnim").on("mouseleave touchend touchcancel", function (e) {
    var el = $(this);
    el.removeClass("analiticAnim-hoverIn");
    el.addClass("analiticAnim-hoverOut");
  });

  $(".mktAnim").on("mouseenter touchstart", function (e) {
    var el = $(this);
    el.addClass("mktAnim-hoverIn");
    el.removeClass("mktAnim-hoverOut");
  });

  $(".mktAnim").on("mouseleave touchend touchcancel", function (e) {
    var el = $(this);
    el.removeClass("mktAnim-hoverIn");
    el.addClass("mktAnim-hoverOut");
  });

  /*------------------------------------------------------------------------------------------------------*\

							GENERAL CSS CLASSES & STYLES

	\*------------------------------------------------------------------------------------------------------*/
  /* SVG FIX for responsive in IE11-*/
  if (IEversion && IEversion < 12) {
    [].slice.call(document.querySelectorAll("svg")).forEach(function (element) {
      const el = $(element);
      if (el.attr("width") && el.attr("width").indexOf("100%") !== -1) {
        var container = el.parent();
        el.removeAttr("width");
        el.removeAttr("height");
        el.each(function () {
          $.each(this.attributes, function (i, a) {
            if (a.name.indexOf("viewBox") !== -1) {
              el.attr({ "viewBox": a.value });
            }
          });
        });

        var vB = el.attr("viewBox").split(" ");
        var dim = [vB[2], vB[3]];
        //console.log('REMOVING ATRR WIDTH + HEIGHT --> width:' + dim[0] + ' height: ' + dim[1] + 'padding to: ' + (dim[1]/dim[0])*50);

        container.css({
          /*'width' : '50%',*/
          "height": "0",
          "padding-top": (dim[1] / dim[0]) * 100 + "%",

          "position": "relative",
        });

        el.css({
          "position": "absolute",
          "top": "0",
          "left": "0",
        });
      }
    });
  }

  /* SVG IE EDGE FIX FOR <text> transform (positioning) */
  [].slice
    .call(document.querySelectorAll("svg text"))
    .forEach(function (element) {
      const txt = $(element);
      if (
        txt.css("transform") &&
        txt.css("transform").indexOf("matrix") !== -1 &&
        !txt.attr("transform")
      ) {
        var newMatrix = txt
          .css("transform")
          .replace(/ /g, "")
          .replace(/,/g, " ");
        txt.attr({ "transform": newMatrix });
      }
    });

  /* HEADER SEPARATOR STYLES */
  [].slice
    .call(document.querySelectorAll(".pct-header-sep"))
    .forEach(function (element) {
      const el = $(element);

      el.append('<span class="pct-sep"></span>');

      el.find(".pct-sep").css({
        "display": "block",
        "position": "relative",
        "margin": "15px auto",
      });

      if (el.hasClass("bg-dark")) {
      } else {
      }
    });

  // FIX FOR TESTIMONIAL NAVIGATION ON MOBILE (ONE COLUMN TILE ONLY)
  window.testimonial_equalHeightTiles = function (slider) {
    var equalHeight = slider.find(".slides").height();

    slider.find(".testimonial_content").height(equalHeight);

    slider.find(".testimonial_content").next();

    slider.find(".testimonial_content .testimonial_content_inner").css({
      "height": "100%",
    });

    //console.log(equalHeight);
  };

  //PORTFOLIO AJAX NAVIGATION

  window.port_nav = function (dir) {
    console.log(dir);
  };

  window.initTestimonialsCarousel = function () {
    "use strict";

    if ($j(".testimonials_c_carousel").length) {
      $j(".testimonials_c_carousel").each(function () {
        var interval = 5000;
        var $this = $j(this);

        if (
          typeof $this.data("auto-rotate-slides") !== "undefined" &&
          $this.data("auto-rotate-slides") !== false
        ) {
          interval = parseFloat($this.data("auto-rotate-slides")) * 1000;
        }

        var slideshow = true;
        if (interval === 0) {
          slideshow = false;
        }

        var controlNav = true;
        if (typeof $this.data("show-navigation") !== "undefined") {
          controlNav = $this.data("show-navigation") == "no" ? false : true;
        }

        var animationSpeed = 600;
        if (
          typeof $this.data("animation-speed") !== "undefined" &&
          $this.data("animation-speed") !== false
        ) {
          animationSpeed = $this.data("animation-speed");
        }

        var numberPerPage = qodeNumberOfTestimonialsItems($this);
        var itemWidth = 0;
        var itemMargin = 0;
        if (typeof numberPerPage !== "undefined" && numberPerPage !== 1) {
          itemWidth = 300;
          itemMargin = 30;
        }

        $this.flexslider({
          animationLoop: true,
          controlNav: controlNav,
          directionNav: false,
          useCSS: false,
          pauseOnAction: true,
          pauseOnHover: false,
          slideshow: slideshow,
          animation: "slide",
          itemMargin: itemMargin,
          minItems: numberPerPage,
          maxItems: numberPerPage,
          itemWidth: itemWidth,
          animationSpeed: animationSpeed,
          slideshowSpeed: interval,
          start: function (slider) {
            initParallax();
            testimonial_equalHeightTiles($this);
          },
          after: function (slider) {},
        });
      });
    }
  };
})(jQuery);
